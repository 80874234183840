<template>
  <el-dialog
      :width="this.componentName === 'result'?'80%':'730px'"
      append-to-body
      title="随机抽取"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      class="randomSelect-component"
      :class="componentName!=='result'?'':'wider'"
      :before-close="beforeClose"
      lock-scroll
  >
    <div class="main">
      <div class="item" v-show="haveStatus">
        <div class="label">选任单位</div>
        <div class="data">{{ data.xrdwmc }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">年度</div>
        <div class="data">{{ data.nd }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">增补计划</div>
        <div class="data">{{ data.jhmc }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">候选人总人数</div>
        <div class="data blue">{{ data.hxrzrs }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">计划人数</div>
        <div class="data blue">{{ data.sjcxrs }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">已抽取人数</div>
        <div class="data blue">{{ data.ycxrs }}</div>
      </div>
      <div class="item" v-show="haveStatus">
        <div class="label">可抽取人数</div>
        <div class="data blue">{{ data.kcxrs }}</div>
      </div>
      <div class="item">
        <div class="label">抽选来源</div>
        <el-cascader
            v-model="form.cxly"
            :options="options.cxly"
            :props="{ checkStrictly: true ,label:'name',value:'id',emitPath:false}"
            placeholder="请选择抽选来源"
            clearable
            :show-all-levels="false"
            size="small"
            :disabled="disabled"
        >
          <template slot-scope="{  data }">
            <span>{{ data.name }}</span>
          </template>
        </el-cascader>
      </div>
      <div class="item">
        <div class="label">增补计划</div>
        <el-select v-model="form.zbjh" placeholder="请选择增补计划" :disabled="disabled" size="small">
          <el-option v-for="item in options.Zbjh" :key="item.id" :label="item.jhmc" :value="item.id"></el-option>
        </el-select>
      </div>

      <div class="item" v-show="haveStatus">
        <div class="label">拟抽选人数</div>
        <el-input v-model="form.ncxrs" @input="input" placeholder="请输入拟抽选人数" size="small"
                  :disabled="disabled"></el-input>
      </div>
    </div>
    <div class="select">
      <component
          :is="componentName"
          @start="start"
          @stop="stop"
          :progress="progress"
          @changeProgress="changeProgress"
          @changeComponent="changeComponent"
          @beforeClose="beforeClose"
          ref="component"
      ></component>
    </div>
  </el-dialog>
</template>

<script>
import category from '@/components/charts/category';

import ready from './component/ready';
import onProgress from './component/onProgress';
import result from './component/result';

import Appointment from '@/api/increase/appointment';
import Public from '@/api/public';

export default {
  // 随机抽取
  name: 'checkCode',
  props: {},
  components: {
    category,
    ready,
    onProgress,
    result,
  },
  data() {
    return {
      show: false,
      componentName: '',
      // haveStatus: false,
      data: {},
      form: {
        cxly: '',
        ncxrs: '',
        zbjh: '',
      },
      options: {cxly: [], Zbjh: []},

      progress: {},
      disabled: false,
    };
  },
  methods: {
    input() {
      this.form.ncxrs = this.form.ncxrs.replace(/[^0-9]/g, '');
    },
    beforeClose(done) {
      if (done instanceof Function) {
        this.toggle(false);
        this.$emit('getList');
        this.$emit('changeShow', false);
        done();
      } else {
        this.toggle(false);
        this.$emit('getList');
        this.$emit('changeShow', false);
      }
    },
    changeComponent(name) {
      if (name !== 'ready') {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.componentName = name;
      if (name === 'result') {
        this.$nextTick(() => {
          this.$refs.component.init(this.progress.items);
        });
      }
    },
    changeProgress(data) {
      this.progress = data;
    },
    toggle(show) {
      this.show = show;
    },
    // 开始抽选
    start() {
      // if (!this.form.cxly) {
      //   this.$alert('请选择抽选来源');
      //   return;
      // }
      if (!this.form.zbjh) {
        this.$alert('请选择增补计划');
        return;
      }
      if (!this.form.ncxrs) {
        this.$alert('请输入拟抽选人数');
        return;
      }
      if (this.form.ncxrs <= 0) {
        this.$alert('请输入正确拟抽选人数');
        return;
      }
      if (this.form.ncxrs > this.data.kcxrs) {
        this.$alert(`当前可抽选人数为---${this.data.kcxrs}`);
        return;
      }
      console.log(this.form.cxly)
      if (typeof this.form.cxly == 'string' || typeof this.form.cxly == 'number') {
        Appointment.start({ncxrs: this.form.ncxrs, cxly: this.form.cxly, zbjh: this.form.zbjh}).then(
            () => {
              this.changeComponent('onProgress');
            }
        );
      } else {
        Appointment.start({
          ncxrs: this.form.ncxrs,
          cxly: this.form.cxly[this.form.cxly.length - 1],
          zbjh: this.form.zbjh
        }).then(
            () => {
              this.changeComponent('onProgress');
            }
        );
      }

    },
    // 抽选结束
    stop() {
      Appointment.stop().then(() => {
        this.changeComponent('result');
      });
    },
    getStatus(cxly, zbjh) {
      // 判断是否有进程 在抽选，如果有，或者没有查看结果的话就展示
      Appointment.randomStatus({cxly, zbjh}).then((res) => {
        this.data = res;
      });
    },
  },
  mounted() {
  },
  created() {
    Promise.all([Public.components({OrgTree: 0, Zbjh: 0}), Appointment.progress()]).then((res) => {
      this.form.ncxrs = res[0].ncxrs;
      this.options.Zbjh = res[0].Zbjh;
      this.options.cxly = res[0].OrgTree;
      if (res[1]) {
        this.changeProgress(res[1]);
        this.form.ncxrs = res[1].ncxrs;
        this.form.zbjh = res[1].current.zbjh;
        this.form.cxly = res[1].current.xrdw;
        this.changeComponent('onProgress');
      } else {
        this.changeComponent('ready');
      }
    });
  },
  computed: {
    haveStatus: function () {
      let {cxly, zbjh} = this.form;
      if (zbjh) {
        this.getStatus(cxly, zbjh);
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}

.el-dialog {
  min-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .item {
      width: 50%;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      box-sizing: border-box;

      .label {
        width: 100px;
        text-align: right;
        padding-right: 20px;
      }

      .data {
        flex: 1;
        padding-left: 10px;
      }

      .blue {
        color: #337fae;
      }

      .el-input {
        flex: 1;
      }

      .el-cascader {
        flex: 1;
      }

      .el-select {
        flex: 1;
      }
    }
  }

  .select {
    height: 100%;
  }
}

.wider {
  ::v-deep .el-dialog {
    margin: 5vh auto 0 auto !important;
  }

  ::v-deep .el-dialog__body {
    display: flex;
    flex-direction: column;
    height: 80vh;
  }
}
</style>
